import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

// root imports
import { fireAuth } from 'services/firebase'
import {
  Nav,
  LogIn,
  Users,
  HistoricUserTotals,
  AudioClasses,
  LiveClasses,
  Coaches,
  Inspirations,
  Teams,
  Subscriptions,
  ManagePushNotifications
} from 'containers'
import { AppContext } from 'contexts'
import theme from 'theme'
import withAuthorization from 'hocs/session/withAuthorization'
import Payment from '../Payment'

const ProtectedUsers = withAuthorization('/login')(Users)
const ProtectedHistoricUserTotals = withAuthorization('/login')(
  HistoricUserTotals
)
const ProtectedClasses = withAuthorization('/login')(AudioClasses)
const ProtectedLiveClasses = withAuthorization('/login')(LiveClasses)
const ProtectedCoaches = withAuthorization('/login')(Coaches)
const ProtectedInspirations = withAuthorization('/login')(Inspirations)
const ProtectedTeams = withAuthorization('/login')(Teams)
const ProtectedSubscriptions = withAuthorization('/login')(Subscriptions)
const ProtectedPushNotifications = withAuthorization('/login')(
  ManagePushNotifications
)

const App = () => {
  const [currentUser, setCurrentUser] = useState(fireAuth.currentUser)

  document.title = '99Walks | Admin Portal'

  useEffect(() => {
    fireAuth.onAuthStateChanged(user => {
      setCurrentUser(user)
    })
  })

  const initialAppState = {
    user: currentUser
  }

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={initialAppState}>
        <CssBaseline />
        <Router>
          <Nav user={currentUser} />
          <Route path='/login' component={LogIn} />
          <Route
            exact
            path='/'
            render={props => <ProtectedUsers auth={currentUser} {...props} />}
          />
          <Route
            path='/historic-user-goals-totals'
            render={props => (
              <ProtectedHistoricUserTotals auth={currentUser} {...props} />
            )}
          />
          <Route
            path='/audio-classes'
            render={props => <ProtectedClasses auth={currentUser} {...props} />}
          />
          <Route
            path='/live-classes'
            render={props => (
              <ProtectedLiveClasses auth={currentUser} {...props} />
            )}
          />
          <Route
            path='/coaches'
            render={props => <ProtectedCoaches auth={currentUser} {...props} />}
          />
          <Route
            path='/inspirations'
            render={props => (
              <ProtectedInspirations auth={currentUser} {...props} />
            )}
          />
          <Route
            path='/teams'
            render={props => <ProtectedTeams auth={currentUser} {...props} />}
          />
          <Route
            path='/subscriptions'
            render={props => (
              <ProtectedSubscriptions auth={currentUser} {...props} />
            )}
          />
          <Route
            path='/push-notifications'
            render={props => (
              <ProtectedPushNotifications auth={currentUser} {...props} />
            )}
          />
          <Route path='/join' render={props => <Payment {...props} />} />
        </Router>
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default App
