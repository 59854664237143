import { devEnvironment } from 'config'

export const AUDIO_CLASSES_TABLE_HEADERS = [
  {
    name: 'Name',
    slug: 'name'
  },
  {
    name: 'Description',
    slug: 'desc'
  },
  {
    name: 'Coach',
    slug: 'coach'
  },
  {
    name: 'Coach (new)',
    slug: 'coachNew'
  },
  {
    name: 'Type',
    slug: 'type'
  },
  {
    name: 'Category (new)',
    slug: 'category'
  },
  {
    name: 'Date Added',
    slug: 'date'
  }
]

export const LIVE_CLASSES_TABLE_HEADERS = [
  {
    name: 'Name',
    slug: 'name'
  },
  {
    name: 'Description',
    slug: 'description'
  },
  {
    name: 'Host',
    slug: 'admins'
  },
  {
    name: 'Start Time',
    slug: 'startTime'
  }
]

export const USERS_TABLE_HEADERS = [
  {
    name: 'Name',
    slug: 'name'
  },
  {
    name: 'Email',
    slug: 'email'
  },
  {
    name: 'Address',
    slug: 'fullAddress'
  },
  {
    name: 'Personal Goal',
    slug: 'monthGoal'
  },
  {
    name: 'Distance Walked',
    slug: 'distance'
  },
  {
    name: 'Goal Completed',
    slug: 'goalCompleted'
  },
  {
    name: 'Team(s)',
    slug: 'teams'
  },
  {
    name: 'Subscription Platform',
    slug: 'paymentPlatform'
  },
  {
    name: 'Subscription State',
    slug: 'subscriptionState'
  },
  {
    name: 'Last Walking Date',
    slug: 'lastWalkingDate'
  },
  {
    name: 'Shipping Status',
    slug: 'shippingStatus'
  }
]

export const HISTORIC_TABLE_HEADERS = [
  {
    name: 'Name',
    slug: 'name',
    sorting: true
  },
  {
    name: 'Email',
    slug: 'email',
    sorting: true
  },
  {
    name: 'Address',
    slug: 'fullAddress'
  },
  {
    name: 'Personal Goal',
    slug: 'monthGoal'
  },
  {
    name: 'Distance Walked',
    slug: 'distance'
  },
  {
    name: 'Goal Completed',
    slug: 'goalCompleted'
  }
]

export const TEAMS_TABLE_HEADERS = [
  {
    title: 'Name',
    field: 'name',
    filtering: false
  },
  {
    title: 'Goal (mi)',
    field: 'goal',
    filtering: false
  },
  {
    title: 'Total (mi)',
    field: 'total',
    filtering: false
  },
  {
    title: 'PIN',
    field: 'pin',
    filtering: false
  }
]

export const SUBSCRIPTIONS_TABLE_HEADERS = [
  {
    title: 'Name',
    field: 'name',
    filtering: false,
    sorting: false
  },
  {
    title: 'Email',
    field: 'email',
    filtering: false
  },
  {
    title: 'First subscribed on',
    field: 'subscriptionStart',
    filtering: false
  },
  {
    title: 'Platform',
    field: 'platform',
    filtering: false
  },
  {
    title: 'Type',
    field: 'type',
    filtering: false
  },
  {
    title: 'Expiration date',
    field: 'subscriptionEnd',
    filtering: false
  },
  {
    title: 'State',
    field: 'state',
    filtering: false
  },
  {
    title: 'On trial',
    field: 'onTrial',
    filtering: false
  }
]

export const COACHES_TABLE_HEADERS = [
  {
    title: 'Name',
    field: 'name',
    filtering: false
  }
]

export const SUBSCRIPTION_TYPE = {
  'fit.99walks.short_duration': 'Monthly',
  monthly_subscription: 'Monthly',
  'fit.99walks.medium_duration': '3-month',
  quarterly_subscription: '3-month',
  'fit.99walks.long_duration': 'Yearly',
  yearly_subscription: 'Yearly'
}

export const SUBSCRIPTION_PLATFORM = {
  ios: 'iOS',
  android: 'Android',
  chargify: 'Chargify'
}

export const INVERT_DIRECTION = {
  asc: 'desc',
  desc: 'asc'
}

export const DATE_PLURAL = {
  day: 'days',
  week: 'weeks',
  month: 'months',
  year: 'years'
}

export const LIVE_CLASS_AUDIO_ROOM_URL = devEnvironment
  ? 'https://99walks-dev.daily.co/99walks-dev-room'
  : 'https://99walks.daily.co/y5gjG62HmEoMlAsadeS4'

export const SUBSCRIPTION_ACTIVE = 'Active'

export const SUBSCRIPTION_INACTIVE = 'None'

export const USERS_QUERY_LIMIT = 40

export const audioClassTypeList = ['exercise', 'meditation', 'podcast', 'book']

export const editorModules = {
  toolbar: [
    [{ header: [1, 2, false] }, { font: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'video'],
    ['clean']
  ]
}
