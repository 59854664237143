// dev
const firebaseDevConfig = {
  apiKey: 'AIzaSyD0eoWA5zwmbMk2VbvRCuhcyqSYEG2xyGs',
  authDomain: 'walks-83611.firebaseapp.com',
  databaseURL: 'https://walks-83611.firebaseio.com',
  projectId: 'walks-83611',
  storageBucket: 'walks-83611.appspot.com',
  messagingSenderId: '463897636216',
  appId: '1:463897636216:web:383ffcd2af9d03b5'
}

// prod
const firebaseProdConfig = {
  apiKey: 'AIzaSyAmNDSgufRqAyziPQJKJiN6bWFF9L06jM8',
  authDomain: 'walks-prod.firebaseapp.com',
  databaseURL: 'https://walks-prod.firebaseio.com',
  projectId: 'walks-prod',
  storageBucket: 'walks-prod.appspot.com',
  messagingSenderId: '295612891244',
  appId: '1:295612891244:web:c26959b3d8a89564'
}

const devEnvironment = process.env.REACT_APP_DEV_ENVIRONMENT
  ? JSON.parse(process.env.REACT_APP_DEV_ENVIRONMENT)
  : true

const firebaseConfig = devEnvironment ? firebaseDevConfig : firebaseProdConfig

export { firebaseConfig, devEnvironment }
