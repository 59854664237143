import axios from 'axios'
import { devEnvironment } from 'config'

const FIREBASE_BASE_API = devEnvironment
  ? process.env.REACT_APP_FIREBASE_BASE_API_DEV
  : process.env.REACT_APP_FIREBASE_BASE_API_PROD
export const login = async (email, password) => {
  const response = await axios.post(`${FIREBASE_BASE_API}/callableAdminPortalLogin`, {
    data: { email, password }
  })
  return response.data
}
