import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  Select,
  InputLabel,
  FormLabel,
  makeStyles
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import moment from 'moment'
import * as yup from 'yup'
import { startCase } from 'lodash'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// root imports
import { FileUpload, LoadingOverlay } from 'components'
import {
  audioClassesScheduledCollection,
  coachesCollection,
  categoriesCollection,
  storageRef
} from 'services/firebase'
import { currentTime } from 'utils/date'
import { trimObject } from 'utils/trim'
import { editorModules } from 'constants/index'

const useStyles = makeStyles(theme => ({
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `10px 0`,
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px'
  },
  menu: {
    width: 200
  },
  input: {
    display: 'none'
  },
  upload: {
    marginTop: theme.spacing(2)
  },
  fab: {
    marginBottom: theme.spacing(2)
  },
  fileUpload: {
    margin: '10px 0'
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    minWidth: 400
  },
  editor: {
    marginTop: '10px',
    width: '100%'
  }
}))

const classTypes = [
  {
    value: 'meditation',
    label: 'Meditation'
  },
  {
    value: 'podcast',
    label: 'Podcast'
  },
  {
    value: 'exercise',
    label: 'Exercise'
  },
  {
    value: 'book',
    label: 'Book'
  }
]

const initialDescription =
  '<p>This is a sample audio class description</p>\n\n<ul>\n' +
  '<li>This is a sample list.</li>\n<li><a href="https://www.google.com">' +
  'This is a sample list with a link.</a></li>\n</ul>'

const initialValues = {
  name: '',
  desc: initialDescription,
  plainDesc: 'This a sample plain text description',
  coachId: '',
  categoryId: '',
  coach: '',
  audio: '',
  type: '',
  url: '',
  date: '',
  filename: '',
  publishDate: moment().format('YYYY-MM-DDTHH:mm'),
  version: 2,
  files: []
}

const AddDialog = ({ onAdd }) => {
  const fileUploadRef = React.useRef(null)

  const [dialog, setDialog] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [coaches, setCoaches] = useState([])
  const [categories, setCategories] = useState([])

  const classes = useStyles()

  async function getCoaches() {
    try {
      setLoading(true)
      let coachesArr = []
      const coachesSnapshot = await coachesCollection
        .orderBy('name', 'asc')
        .get()
      coachesSnapshot.forEach(coach =>
        coachesArr.push({ ...coach.data(), id: coach.id })
      )
      setCoaches(coachesArr)
    } finally {
      setLoading(false)
    }
  }

  async function getCategories(type) {
    if (type) {
      try {
        setLoading(true)
        let categoriesArr = []
        const categoriesSnapshot = await categoriesCollection
          .where('type', '==', type)
          .get()
        categoriesSnapshot.forEach(cat =>
          categoriesArr.push({ id: cat.id, ...cat.data() })
        )
        setCategories(categoriesArr)
      } finally {
        setLoading(false)
      }
    }
  }

  React.useEffect(() => {
    const loadMetadata = async () => {
      const file = values.files && values.files.length > 0 && values.files[0]
      if (file) {
        try {
          const audio = await getAudioDuration(file)
          setValues(values => ({
            ...values,
            audio: audio.file,
            duration: audio.duration,
            date: currentTime()
          }))
        } catch (e) {
          // pass
        }
      } else {
        setValues(values => ({
          ...values,
          audio: initialValues.audio,
          duration: initialValues.duration,
          date: initialValues.date
        }))
      }
    }

    getCoaches()
    loadMetadata()
    getCategories(values.type)
  }, [values.files, values.type])

  const toggleDialog = () => {
    setDialog(open => !open)
  }

  const handleChange = name => e => {
    setValues({ ...values, [name]: e.target.value })
  }

  const resetValues = () => {
    setValues(initialValues)
  }

  const saveToDB = async (filename, url) => {
    delete values.audio
    delete values.files

    try {
      const coach = coaches.filter(coach => coach.id === values.coachId)
      await audioClassesScheduledCollection.add({
        ...trimObject(values),
        publishDate: moment(values.publishDate).unix(),
        filename,
        url,
        coach: coach[0].name
      })
      onAdd()
      toggleDialog()
    } finally {
      setLoading(false)
      resetValues()
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    const filename =
      values.type === 'exercise'
        ? `${values.audio.name}`
        : `${values.type}/${values.audio.name}`

    const audioUpload = storageRef
      .child(`audio-classes/${filename}`)
      .put(values.audio)

    audioUpload.on(
      'state_changed',
      snapshot => {
        // let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      error => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        audioUpload.snapshot.ref.getDownloadURL().then(url => {
          saveToDB(filename, url)
        })
      }
    )
  }

  const getAudioDuration = file => {
    return new Promise(resolve => {
      var objectURL = URL.createObjectURL(file)
      var audio = new Audio([objectURL])
      audio.addEventListener(
        'canplaythrough',
        () => {
          URL.revokeObjectURL(objectURL)
          resolve({
            file,
            duration: audio.duration
          })
        },
        false
      )
    })
  }

  const isNameValid = yup
    .string()
    .required()
    .isValidSync(values.name)

  const isCoachValid = yup
    .string()
    .required()
    .isValidSync(values.coachId)

  const isCategoryValid = yup
    .string()
    .required()
    .isValidSync(values.categoryId)

  const isDescriptionValid = yup
    .string()
    .required()
    .test('is-valid', 'Please set a description', v => v !== initialDescription)
    .isValidSync(values.desc)

  const isPlainDescriptionValid = yup
    .string()
    .required()
    .test(
      'is-valid',
      'Please set a plain text description',
      v => v !== initialDescription
    )
    .isValidSync(values.plainDesc)

  const isTypeValid = yup
    .string()
    .required()
    .isValidSync(values.type)

  const isPublishDateValid = yup
    .string()
    .required()
    .isValidSync(values.publishDate)

  const isAudioFileValid = !!values.audio

  const canSubmit =
    isNameValid &&
    isCoachValid &&
    isCategoryValid &&
    isDescriptionValid &&
    isPlainDescriptionValid &&
    isTypeValid &&
    isPublishDateValid &&
    isAudioFileValid

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <Fab
        color='primary'
        onClick={toggleDialog}
        aria-label='Add'
        className={classes.fab}
        size='small'
      >
        <AddIcon />
      </Fab>
      <Dialog open={dialog} onClose={toggleDialog}>
        <DialogTitle>New Audio Class</DialogTitle>
        <DialogContent>
          <form className={classes.form} autoComplete='off'>
            <TextField
              fullWidth
              disabled={loading}
              required
              id='name'
              label='Name'
              value={values.name}
              margin='normal'
              onChange={handleChange('name')}
            />
            <FormControl className={classes.formControl} required>
              <InputLabel id='select-coach-label'>Coach</InputLabel>
              <Select
                labelId='select-coach-label'
                id='coach-select'
                value={values.coachId}
                displayEmpty
                onChange={handleChange('coachId')}
              >
                {coaches &&
                  coaches.map(coach => (
                    <MenuItem key={coach.id} value={coach.id}>
                      {startCase(coach.name)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} required>
              <FormLabel id='editor-label'>Description</FormLabel>
              <div id='editor-container'>
                <ReactQuill
                  labelId='editor-label'
                  className={classes.editor}
                  modules={editorModules}
                  theme='snow'
                  value={values.desc}
                  onChange={v => setValues({ ...values, desc: v })}
                  bounds='#editor-container'
                />
              </div>
            </FormControl>
            <TextField
              fullWidth
              disabled={loading}
              required
              multiline
              rows={6}
              id='plainDesc'
              label='Plain Text Description'
              value={values.plainDesc}
              type='textfield'
              margin='normal'
              onChange={handleChange('plainDesc')}
              error={values.plainDesc === initialDescription}
              helperText={
                values.plainDesc === initialDescription &&
                'Please set a plain text description.'
              }
            />
            <TextField
              fullWidth
              disabled={loading}
              required
              id='type'
              select
              label='Select type'
              value={values.type}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              helperText='Please select the class type.'
              margin='normal'
              onChange={handleChange('type')}
            >
              {classTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {values.type && (
              <FormControl className={classes.formControl} required>
                <InputLabel id='select-coach-label'>Category</InputLabel>
                <Select
                  labelId='select-category-label'
                  id='category-select'
                  value={values.categoryId}
                  displayEmpty
                  onChange={handleChange('categoryId')}
                >
                  {categories &&
                    categories.map(category => (
                      <MenuItem key={category.id} value={category.id}>
                        {startCase(category.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <TextField
              fullWidth
              margin='normal'
              label='Publish Date & Time'
              placeholder='Enter the publish date & time'
              type='datetime-local'
              InputLabelProps={{
                shrink: true
              }}
              value={values.publishDate}
              onChange={handleChange('publishDate')}
              disabled={loading}
            />
            <Typography
              variant='caption'
              color='textSecondary'
              style={{ marginBottom: -5, marginTop: 5 }}
            >
              Audio Class
            </Typography>
            <FileUpload
              labelIdle={
                'Drag & Drop your audio file or ' +
                '<span class="filepond--label-action"> Browse </span>'
              }
              disabled={loading}
              acceptedFileTypes={['audio/mpeg', 'audio/mp3']}
              ref={fileUploadRef}
              files={values.files}
              className={classes.fileUpload}
              onupdatefiles={fileItems => {
                setValues(values => ({
                  ...values,
                  files: fileItems.map(fileItem => fileItem.file)
                }))
              }}
              onremovefile={(e, file) => {
                if (values.files !== undefined && values.files.length !== 0) {
                  setValues(values => ({
                    ...values,
                    files: values.files.filter(f => f === file)
                  }))
                }
              }}
            />
            <div className={classes.actions}>
              <Button onClick={toggleDialog} disabled={loading}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={loading || !canSubmit}
              >
                Schedule Audio Class
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default AddDialog
